/* --------------------- SUPOPORT US  --------------------- */


#support-us-page .visit-us-section .top-text {
    padding-bottom: 1em;
    border-bottom: 3px solid var(--primary);
}

.support-row {
    margin: 0 auto;
    max-width: 1200px;
    background-color: var(--offwhite);
    padding: 20px;
    padding-top: 3em;
    padding-bottom: 2em;
    text-align: center;
}

.support-body {
    padding-top: 4em;
    padding-bottom: 5em;
}

.support-row h1 {
    font-weight: 800;
    color: var(--primary);
}

.support-row .row-1,
.support-row .row-2 {
    border-right: 5px solid var(--primary);
}

.support-row .row-1 a,
.support-row .row-2 a,
.support-row .row-3 a
 {
    color: var(--primary);
    font-weight: 700;
}

/* --------------------- VOLUNTEER  --------------------- */


.volunteer-col .card {
    text-align: center;
    color: white;
    background-color: var(--primary);
    height: 100%;
}

.middle-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #004e729d;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.volunteer-col .volunteer-body {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.volunteer-body blockquote {
    padding: 2em;
    font-style: italic;
} 

.wrapper{
  display: flex;
  max-width: 900px;
  position: relative;
  margin: 0 auto;
}

.wrapper img:hover {
  opacity: 0.5;
} 

.wrapper i{
  top: 50%;
  height: 44px;
  width: 44px;
  color: var(--primary);
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  text-align: center;
  line-height: 44px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.wrapper i:active{
  transform: translateY(-50%) scale(0.9);
}
.wrapper i:hover{
  background: #f2f2f2;
}
.wrapper i:first-child{
  left: -22px;
  position: absolute;
  z-index: 99;
}
.wrapper i:last-child{
  right: -22px;
}
.wrapper .carousel{
  font-size: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.carousel.dragging{
  cursor: grab;
  scroll-behavior: auto;
}
.carousel.dragging img{
  pointer-events: none;
}
.carousel img{
  height: 340px;
  object-fit: cover;
  user-select: none;
  margin-left: 14px;
  width: calc(100% / 3);
}
.carousel img:first-child{
  margin-left: 0px;
}
@media screen and (max-width: 900px) {
  .carousel img{
    width: calc(100% / 2);
  }
}
@media screen and (max-width: 550px) {
  .carousel img{
    width: 100%;
  }
}

#volunteer-card .card-second-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

#volunteer-card .card-second-child p {
  font-size: 1em;
}

/* --------------------- MEMBERSHIP  --------------------- */

.member-col .card {
  text-align: center;
  color: white;
  background-color: var(--primary);
  height: 100%;
}

.member-col .member-body {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 2em;
  padding-bottom: 2em;
}

.member-body blockquote {
  padding: 2em;
  font-style: italic;
} 

.family-option blockquote {
  padding: 2em;
  padding-bottom: 0em;
}

/* --------------------- TELETHON  --------------------- */


#telethon-page .top-text {
  border-bottom: 3px solid var(--primary);
  padding-bottom: 1em;
}

#telethon-page .top-text p a {
  color: var(--primary);
}

#watchlive {
  float: left !important;
}

#telethon-page .top-text .row a {
  float: right;
  position: relative;
  top: -1.5em;
  width: 200px;
  margin-right: 2em;
};


#telethon-page .visit-us-section {
  width: 90%;
  margin: 0 auto;
  height: 60%;
  max-width: 1000px;
  padding-bottom: 0em;
}

#telethon-page .support-body {
  width: 100%;
  margin: 0 auto;
  height: 60%;
  max-width: 1200px;
  padding-bottom: 0em;
}

#telethon-page .support-body img {
  width: 900px;
}

#telethon-page .support-body ul {
  font-weight: 600;
  color: var(--primary);
  font-style: italic;
}

#telethon-page .telethon-options {
  background-color: var(--primary);
  color: #fff;
  height: 520px;
  padding: 2em;
  margin-top: 1.3em;
}

#telethon-page .telethon-options h2 {
  color: #fff;
}

#telethon-page .telethon-options .popup_main {
  margin-bottom: 2em;
  width: 100%;
}

#telethon-page .past-telethon .row {
  margin-bottom: 0.8em;
}

#telethon-page .past-telethon .row p {
    padding-right: 4em;
}

#telethon-page .past-telethon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#telethon-page .past-telethon-body {
  background-color: var(--offwhite);
  padding: 2em;
}

#telethon-page .supporters {
  text-align: center;
}

#telethon-page .supporters p {
  width: 70%;
  margin: 0 auto;
}

#telethon-page .supporters-body {
  padding-top: 2em;
  text-align: left;
  padding-bottom: 2em;
}

#telethon-page .supporters-body h3 {
  color: var(--primary);
  font-family: "Libre Basketville";
  font-weight: 600;
}

#telethon-page .supporters-body p {
  text-align: left;
  width: 100%;
  padding-top: 1em;
}


.open_popup{
  border:2px solid white;
  color:var(--primary);
  background-color: white;
  width: 100%;
  padding: 15px 20px;
  opacity: 1;
  text-transform: uppercase;
  font-weight: 700;
}

.open_popup:hover {
  opacity: 0.9;
}

.popup_body{
  display:none;
  position:fixed;
  z-index: 999;
  top: 20%;left:0;right:0;bottom:0;
  -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    -ms-transition: 300ms;
    transition: 300ms;
}
.popup_body_show{
  display:block;
  -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    -ms-transition: 300ms;
    transition: 300ms;
}
.popup_back{
  background: rgba(0,0,0,0.6);
  position: fixed;
  left:0;right:0;top:0;bottom:0;
}
.popup_contain{
  position:relative;
  background:#fff;
  min-width:300px;
  max-width:70%;
  margin:100px auto 50px;
  font-weight: 600;
  font-size: 1.5em;
  padding: 50px;
  color: var(--primary);
}
.popup_close{
  padding:10px;
  position: absolute;
  right:10px;
  font-weight: 800;
  color: var(--primary);
  border-radius: 50%;
  cursor: pointer;
}



@media screen and (max-width: 940px) {

  .support-row .row-1,
  .support-row .row-2 {
    border-bottom: 3px solid var(--primary);
    border-right: none;
    margin-bottom: 2em;
    padding-bottom: 2em;
  }

  .volunteer-col,
  .member-col {
    padding-bottom: 2em;
  }

  .volunteer-col .card {
    border-radius: 0em;
    padding-top: 1em;
  }

  .member-col .card {
    border-radius: 0em;
  }

  #volunteer-card {
    display: none;
  }

  #telethon-page .top-text h2 {
    padding-bottom: 3em;
  }


  #telethon-page .top-text .row a {
    margin-bottom: 2em;
  }

  #telethon-page .top-text {
    text-align: center;
  }

  #telethon-page .support-body img {
    width: 400px;
  }

  #telethon-page .telethon-options {
    background-color: var(--primary);
    color: #fff;
    height: 950px;
    padding: 2em;
    margin-top: 1.8em;
  }  

  #telethon-page .past-telethon .row p {
    padding-right: 0em;
  }

  #telethon-page .past-telethon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #telethon-page .supporters p {
    width: 90%;
    margin: 0 auto;
  }

  #telethon-page .supporters .main-button {
    margin-bottom: 2em;
  }

}
