/* ---------------------  NEWS  --------------------- */

.news-section {
    max-width: 1200px;
    margin: 0 auto;
}

.news-section .top-news {
    max-width: 1050px;
    margin: 0 auto;
}

.news-body {
    padding-bottom: 3em;
}

.legends img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.logos-legends img {
    object-fit: contain;
}

.legends p {
    font-size: 1em;
    font-weight: 900;
    padding-top: 1em;
    color: var(--primary);
}

.news-subpage strong {
    font-weight: 900;
}

/* ---------------------  OUR HISTORY  --------------------- */

#our-history-page .visit-us-section .top-text {
    padding-bottom: 1em;
    border-bottom: 3px solid var(--primary);
}

#our-history-page .dates-row {
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
    padding: 3em;
    padding-top: 5em;
}

#our-history-page .dates-row h1 {
    font-weight: 800;
    color: var(--primary);
}

#our-history-page .dates-row .row-2 {
    border-left: 5px solid var(--offwhite);
    border-right: 5px solid var(--offwhite);
}

#our-history-page .card-title {
    font-size: 2em;
}

.history-card-img {
    padding-top: 1em;
    padding-bottom: 2em;
    width: 500px;
    height: 500px;
    object-fit: cover;
}

#our-history-page .map-row {
    padding-top: 6em;
    padding-bottom: 5em;
}

#our-history-page .google-map {
    height: 500px;
}

#our-history-page .map-row a {
    color: black;
}

#our-history-page .map-row a:hover {
    color: var(--primary);
}

#our-history-page .map-row-text {
    padding-top: 6em;
}

/* ---------------------  ABOUT US  --------------------- */


#staff-section {
    max-width: 1300px;
    margin: 0 auto;
}

.side-menu-container {
    display: flex;
}

.side-menu {
    background-color: #F4F5F5;
    color: black;
    width: 250px;
    height: 255px;
    position: relative;
    text-align: left;
}

.side-menu ul {
    list-style: none;
    padding: 0;
}

.side-menu button {
    width: 100%;
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    text-align: left;
    padding-left: 1em;
    background-color: transparent;
    border: none;
    padding-top: 1em;
    padding-bottom: 1em;
}

.mobile-side-menu {
    display: none;
}

.content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 4em;
    width: 850px;
    box-shadow: rgba(25, 25, 25, 0.1) 0px 8px 30px 0px;
    border-bottom: 0.3em solid var(--primary);
}

.content .image-body {
    width: 300px;
}

.content .image-body img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    /* border-radius: 50%; */
}

.content .row {
    margin: 0 auto;
    padding-bottom: 2em;
}

.content h2 {
    font-weight: 700;
    font-family: "Libre Basketville";
}

.content h3 {
    font-style: italic;
    font-weight: 300;
    padding-bottom: 0.75em;
}

.content .row .staff-text {
    width: 500px;
    margin: 0 auto;
}


.content .row .staff-text p {
    font-size: 1em;
}

.hidden {
    display: none;
}

#content3 h2,
#content4 h2 {
    font-size: 1.5em;
    line-height: 2em;
}

/* ---------------------  CONTACT US  --------------------- */

.contact-row {
    margin: 0 auto;
    max-width: 1200px;
    background-color: var(--offwhite);
    padding: 20px;
    padding-top: 3em;
    padding-bottom: 2em;
}

.contact-row h4 {
    font-family: "Libre Basketville";
    font-weight: 600;
}

.contact-row h1 {
    font-weight: 800;
    color: var(--primary);
}

.contact-row .row-1,
.contact-row .row-2 {
    border-right: 5px solid var(--primary);
}

.contact-row .row-1 a,
.contact-row .row-2 a {
    color: var(--primary);
}

#contact-page .google-map {
    height: 81%;
    position: relative;
    top: 5%
}

#contact-page .map-row {
    padding-top: 8em;
    width: 100%;
    padding-bottom: 4em;
}

.contact-form .form-label {
    font-weight: 700;
    font-size: 1.3em;
    font-family: "Libre Basketville";
}

.contact-form .form-control {
    background-color: var(--offwhite);
}

.news-subpage .main-img {
    width: 60%;
    padding-top: 3em;
    padding-bottom: 2em;
}

.news-subpage .container {
    padding-bottom: 2em;
    margin-bottom: 5em;
    border-bottom: 0.3em solid var(--primary);
}

#telethon-24 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.side-img {
    width: 100%;
    height: 100%;
}

.zogby-vid {
    margin-top: 2em;
    width: 100%;
    height: 600px;
}

#content4 .row img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 940px) {

    .side-menu {
        display: none;
    }

    .mobile-side-menu {
        display: block;
    }

    .mobile-side-menu {
        background-color: #F4F5F5;
        color: black;
        width: 350px;
        margin: 0 auto;
        height: 255px;
        position: relative;
        text-align: left;
        margin-bottom: 3em;
    }
    
    .mobile-side-menu ul {
        list-style: none;
        padding: 0;
    }
    
    .mobile-side-menu button {
        width: 100%;
        text-decoration: none;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.05em;
        text-align: left;
        padding-left: 1em;
        background-color: transparent;
        border: none;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .content {
        flex-grow: 1;
        padding: 20px;
        width: 350px;
        margin: 0 auto;
        box-shadow: rgba(25, 25, 25, 0.1) 0px 8px 30px 0px;
        border-bottom: 0.3em solid var(--primary);
    }
    
    .content .image-body {
        width: 300px;
        padding-bottom: 1em;
    }
    
    .content .image-body img {
        width: 230px;
        height: 250px;
        object-fit: cover;
        /* border-radius: 180px; */
    }
    
    .content .row {
        margin: 0 auto;
        padding-bottom: 2em;
    }
    
    .content h2 {
        font-weight: 700;
    }
    
    .content h3 {
        font-style: italic;
        font-weight: 300;
        padding-bottom: 0.75em;
    }
    
    .content .row .staff-text {
        width: 500px;
        margin: 0 auto;
    }

    #our-history-page .dates-row .row-2 {
        border-left: none;
        border-right: none;
        border-top: 5px solid var(--offwhite);
        border-bottom: 5px solid var(--offwhite);   
        padding-top: 1em; 
        margin-bottom: 1em;
    }

    #our-history-page .white-card {
        display: none;
    }

    #our-history-page .mobile-content .card-title {
        font-size: 2em;
    }

    .history-card-img {
        width: 100%;
        height: 350px;
    }

    #our-history-page .mobile-card {
        margin: 0 auto;
    }

    #our-history-page iframe {
        width: 100%;
    }

    .news-section .top-news {
        max-width: 350px;
        margin: 0 auto;
    }

    .contact-row {
        margin: 0 auto;
        max-width: 1200px;
        background-color: var(--offwhite);
        padding: 20px;
        padding-top: 3em;
        padding-bottom: 2em;
    }
    
    .contact-row h1 {
        font-weight: 800;
        color: var(--primary);
    }
    
    .contact-row .row-1,
    .contact-row .row-2 {
        border-bottom: 3px solid var(--primary);
        border-right: none;
        margin-bottom: 2em;
        padding-bottom: 2em;
    }

    .contact-row .row-1 {
        font-size: 0.7em;
    }
    
    .contact-row .row-1 a,
    .contact-row .row-2 a {
        color: var(--primary);
    }

    #contact-page .google-map {
        height: 350px;
        position: relative;
        top: 2em;
    }

    #about-page img {
        width: 100%;
    }

    .news-subpage .main-img,
    .news-subpage img {
        width: 100%;
    }

    .legends-2024-btns {
        width: 100%;
    }

    .legends-2024-btns a {
        margin-bottom: 2em;
    }

    .legends-2024 img {
        width: 310px;
        margin-bottom: 2em;
    }

    .zogby-vid {
        margin-top: 2em;
        width: 100%;
        height: 300px;
    }
}
