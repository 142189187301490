:root {
  --primary: #004D72;
  --offwhite: #F4F5F5;
  --lightshadow: 0px 8px 8px 0px rgba(0,0,0,0.1);
  --cardshadow: 0 8px 30px 0 rgba(25,25,25,.1);
}

.hide {
  display: none;
}

/* Mobile */

@media only screen and (min-width: 0em) {
  body,
  html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  body {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .container {
    position: relative;
    width: 92%;
    margin: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p,
  li,
  a {
    line-height: 1.5em;
  }

  p {
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 0.05em;
    font-family: "Palatino Linotype";
  }
  
  a:hover,
  button:hover {
    cursor: pointer;
  }
  .main-button-white {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    width: auto;
    line-height: 3.5em;
    text-align: center;
    padding: 0 2em;
    position: relative;
    z-index: 1;
    background-color: white;
    border: 3px solid white;
    color: var(--primary);
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
  }
  .main-button-white:hover {
    color: white;
    border:  3px solid #212121;
    text-decoration: none;
  }
  .main-button-white:hover:before {
    width: 100%;
  }
  .main-button-white:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s;
  }

  .main-button {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    width: auto;
    line-height: 3em;
    color: white;
    text-align: center;
    padding: 0.5em 2em;
    position: relative;
    z-index: 1;
    background-color: var(--primary);
    border: 3px solid var(--primary);
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
  }
  
  .main-button:hover {
    color: white;
    border:  3px solid black;
    text-decoration: none;
  }
  .main-button:hover:before {
    width: 100%;
  }
  .main-button:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s;
  }

  .section-header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }

  .page-header {
    position: relative;
  }
  
  .page-header-img {
    position: relative;
    top: -60px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
  }

  .page-header-img-body {
    position: relative;
    top: 1em;
  }
  
  .page-header-img-body::before {
    top: -60px;
    z-index: 10;
    content: '';
    width: 100%;
    height: 400px;
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0) 0%, #d1e8f400 120%);
    display: none;
  }
  
  .page-header-title {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    color: white;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    margin: 0 auto;
  }

  .page-header-title h1 {
    font-size: 4em;
    font-family: 'Libre Baskerville';
  }

  .page-header-title h4 {
    font-weight: 200;
  }

}
/* Reset Margins */
@media only screen and (min-width: 1024px) {
  body,
  html {
    padding: 0;
    margin: 0;
  }
}
/* Scale full website with the viewport width */
@media only screen and (min-width: 2000px) {
  body,
  html {
  }
}
/* Desktop */
@media only screen and (min-width: 64em) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.375em rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #f0f0f0;
  }
  ::-webkit-scrollbar {
    width: 0em;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.625em;
    background-color: #F5F5F5;
  }
}

@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');
@import url('https://fonts.googleapis.com/css?family=Palatino+Linotype');

@media screen and (max-width: 940px) {

  .page-header {
    position: relative;
  }
  
  .page-header-img {
    position: relative;
    top: -60px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
  }

  .page-header-img-body {
    position: relative;
    top: 1em;
  }
  
  .page-header-img-body::before {
    top: -60px;
    z-index: 10;
    content: '';
    width: 100%;
    height: 400px;
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0) 0%, #d1e8f400 120%);
    display: none;
  }
  
  .page-header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    color: white;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    margin: 0 auto;
  }

  .page-header-title h1 {
    font-size: 2em;
  }

  .page-header-title h4 {
    font-weight: 200;
    width: 100%;
  }

}

a {
  color: var(--primary); 
}

/* -----------------------  POPUP  --------------------------- */


#popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity .3s;
  display: none;
}

#popup {
  position: relative;
  left: 73%;
  top: 54%;
}

.popup-body {
  max-width: 330px;
  max-height: 200px;
}

.popup-left {
  width: 100%;
  object-fit: cover;
  background-color: #E44242;
}

.popup-body img {
  width: 330px;
  height: 300px;
  object-fit: cover;
  margin-top: -2.5em;
  left: -1em;
  position: relative;
  z-index: 1;
}

#popup-right {
  background-color: #fff;
  padding: 1rem;
  box-sizing: border-box;
  height: auto;
  transition: ease-in-out 0.2s;
}

#popup h1 { 
  font-size: 1.5em;
  color: #797d81;
  font-weight: 400;
  text-align: center;
  font-family: "Varela round";
}

#popup h1 span { 
  font-weight: 800;
  font-size: 2em;
  color: #039ae0;
}

#popup h2 {
  color: green;
  font-weight: 800;
  padding-bottom: 0.6em;
  margin-bottom: 0.6em;
  margin-top: -1em;
  text-align: center;
  border-bottom: 2px solid rgb(159, 159, 159);
}

#popup h2 span { 
  color: red;
}

#popup-form iframe {
  max-height: 300px;
}

#form-popup {
  background-color: #00B0FF;
  border: none;
  padding: 1em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 1em;
}

#form-popup:hover { 
  background-color: #3abaf5;
}

#close-popup {
  border: none;
  border: 2px solid var(--primary);
  padding: 0.1em;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  font-weight: 800;
  font-family: "Varela Round";
  position: relative;
  z-index: 2;
  left: 45%;
  margin-bottom: -3em;
  transition: ease-in-out 0.3s;
}

#close-popup:hover { 
  background-color: var(--primary);
  color: #fff;
}

#popup-hidden-content {
  text-align: center;
  padding-bottom: 1em;
  color: #6c757d;
  font-size: 18px;
  width: 90%;
  margin: 0 auto;
}

#popup-hidden-content p span {
  font-weight: 700;
  color: #454545;
}

@media only screen and (max-width: 900px) {

  #popup {
    position: relative;
    left: 0%;
    top: 10%;
  }

}
