#top-nav {
  background-color: var(--offwhite);
}

.top-nav-body {
  max-width: 1400px;
  margin: 0 auto;
}

.top-nav-item {
  position: relative;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: flex-start;
  padding-left: 3em;
  left: -5%;
}

.top-nav-link {
  text-decoration: none;
  color: black;
  font-weight: 300;
  display: block;
}

.top-nav-link:hover {
  color: var(--primary);
  opacity: 0.7;
}

.top-nav-header {
  font-weight: 700;
  font-family: "libre Basketville";
  font-size: 1.1em;
}

#bottom-nav {
  padding-left: 5em;
  max-width: 1400px;
  margin: 0 auto;
}

#logo_img {
  width: 200px;
}

#bottom-nav .nav-link {
  font-weight: 700;
  color: black;
  padding-right: 2em;
  font-size: 1.2em;
  font-family: "libre Basketville";
}

#bottom-nav .nav-link:hover {
  color: var(--primary);
  opacity: 0.8;
}

.dropdown_btn a {
  margin-left: 0px;
  margin-right: 0px;
  text-decoration: none;
  font-size: inherit;
}


.dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FAFBFC;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
  width: 210px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.dropdown-content a {
  float: none;
  color: black;
  line-height: 2.5em;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: inherit;
  font-weight: 500;
  left: 1em;
  position: relative;
  width: 80%;
  font-family: "Palatino Linotype";
}

.dropdown-content a:hover {
  background-color: #FAFBFC;
  color: var(--primary);
  opacity: 0.7;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#bottom-nav-education {
  font-size: 0.97em;
}

#bottom-nav-mobile-tel {
  position: relative;
  top: -1em;
  left: 1em;
  border-radius: 50%;
}

#bottom-nav-mobile-tel img {
  border-radius: 50%;
}

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
  left: -1em;
}

.navbar-toggler:focus {
  border: 0 !important;
  box-shadow: none !important;
}
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--primary);
  opacity: 1;
  left: 0;
  transition: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  width: 60%;
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* state when navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

@media screen and (max-width: 940px) {

  #top-nav {
    display: none;
  }

  #logo_img {
    width: 120px;
  }

  #bottom-nav {
    padding-left: 2em;
    text-align: right;
  }

  #bottom-nav-mobile-tel img {
    z-index: 999;
    position: relative;
  }

  .sticky {
    position: fixed;
    z-index: 9999999;
    width: 100%;
    top: 0;
    background-color: #fff;

  }
}
