/* --------------------- RESEARCH REQUEST  --------------------- */


#research-request-page span a {
    color: var(--primary);
}

#research-request-page .visit-us-section {
   padding-bottom: 5em;
}

/* --------------------- RESEARCH & LEARN  --------------------- */


.research-img-right {
    background-color: var(--primary);
    color: #fff;
    margin-top: 3em;
    text-align: center;
}

.research-img-left .home-shop-content {
    padding-left: 1em;
}


.research-img-right .home-shop-content {
    padding-left: 3em;
}

.research-img-left .home-shop-content p,
.research-img-right .home-shop-content p {
    width: 100%;
    padding-bottom: 1em;
}

.research-img-right img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.research-img-left {
    background-color: var(--offwhite);
    color: black;
    margin-top: 3em;
    text-align: center;
}

.research-img-left img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

/* --------------------- VISIT RESEARCH LIBRARY  --------------------- */


#visit-research-library-page a {
    color: var(--primary);
}

#visit-research-library-page h2 {
    text-align: center;
}

#visit-research-library-page .main-button {
    color: #fff;
}

#visit-research-library-page .home-shop-content {
    padding-bottom: 3em;
}

#visit-research-library-page .research-img-left {
    text-align: left;
    height: 380px;
}

#visit-research-library-page .research-img-left h2 {
    text-align: left;
    padding-top: 1em;
}

#visit-research-library-page img {
    height: 380px;
    width: 100%;
    object-fit: cover;
}

#visit-research-library-page .visit-us-section {
    padding-bottom: 5em;
}

#visit-research-library-page .visit-us-section .last-item {
    margin-bottom: 4em;
}

@media screen and (max-width: 940px) {

    .home-shop-content {
        margin: 0;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        max-width: 800px;
        padding: 1em;
    }

    .home-shop-content-text {
        padding: 0.1em;
    }

    #visit-research-library-page .research-img-left {
        padding-bottom: 60em;
        width: 100%;
    }

    #visit-research-library-page .research-img-left h2 {
        padding-top: 1em;
    }


    #visit-research-library-page .last-item {
        padding-bottom: 55em;
    }

    #visit-research-library-page .research-img-left a {
        font-size: 0.8em;
    }

    #visit-research-library-page .visit-us-section {
        padding-bottom: 0em;
    }

    .research-request-link {
        font-size: 0.8em;
    }

    #education-page .home-shop-content {
        padding-left: 2em;
        width: 100%;
    }

}

#request {
    max-width: 800px;
}