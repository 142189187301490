/* ---------------------  VISIT US  --------------------- */

.visit-us-section {
    width: 90%;
    margin: 0 auto;
}

.visit-us-section h2, 
.visit-us-section h5 {
    font-weight: 600;
    color: var(--primary);
    padding-bottom: 1em;
    font-family: "Libre Basketville";
    font-size: 1.5em;
}

.visit-us-section h2 {
    font-size: 1.8em;
}

.visit-us-section p {
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 0.05em;
}

.image-label {
    color: #9a9a9a;
    font-style: italic;
}

.visit-us-section .google-map {
    padding-bottom: 40%;
    position: relative;
}

.visit-us-section .google-map iframe {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

/* ---------------------  EXHIBITS  --------------------- */


.white-card {
    box-shadow: var(--cardshadow);
    border-radius: 0px;
    border: none;
}

.white-card-body {
    padding-bottom: 3em;
    max-width: 1400px;
    margin: 0 auto;
}

.white-card .card-title {
    font-weight: 700;
    color: var(--primary);
    border-bottom: 0.1em solid var(--primary);
    font-family: 'Libre Baskerville';
}

.white-card-image {
    transition: 0.1s;
    object-fit: cover;
}

.visit-us-section .card-content .main-button {
    color: white;
    padding: 0em 1.4em;
}

.visit-us-section .card-content .main-button:hover {
    color: white;
}

.visit-us-section .card-content .card-text {
    padding: 2em;
    font-size: 1.2em;
}

.card-second-child {
    padding-bottom: 1em;
}

.full-exhibit-img {
    width: 100%;
    height: 100%;
}

/* ---------------------  PROGRAMS  --------------------- */


#programs-page h2 {
    text-align: center;
    padding-bottom: 2em;
}

#programs-page .row {
    padding-bottom: 3em;
}

.programs-card {
    background-color: var(--primary);
    border-radius: 0px;
    border: none;
    border-bottom: 0.2em solid var(--primary);
    height: 100%;
}

.programs-card .card-title {
    font-weight: 700;
    color: white;
    font-size: 2em;
}

.programs-card .card-text {
    color: white;
}

.programs-card .card-body {
    height: 100%;
}

.programs-card .card-link {
    float: right;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    color: var(--primary);
}

.programs-card .card-link:hover {
    opacity: 0.6;
}

#programs-page .main-button {
    background-color: white;
    color: var(--primary);
}

#programs-page .main-button:hover {
    color: white;
}

.programs-top-row .card {
    margin-bottom: -4em;
}

.programs-vids-section .top {
    margin-bottom: -2em;
    padding-top: 1em;
}

.programs-vids-section .video-body {
    padding: 1em;
}

.programs-vids-section iframe {
    width: 100%;
    height: 180px;
}

.programs-vids-section h2 {
    font-weight: 600;
    color: var(--primary);
    font-family: "Libre Basketville";
    font-size: 1.8em;
    text-align: left;
    padding-top: 1em;
}

.programs-vids-section .calendar h2 { 
    font-weight: 600;
    color: var(--primary);
    font-family: "Libre Basketville";
    font-size: 1.4em;
    text-align: left;
    margin-top: -1em;
    margin-bottom: 2em;
}

.programs-vids-section .calendar .video-button {
    margin-top: -1.5em;
}

.video-button {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    width: auto;
    line-height: 3em;
    color: white;
    text-align: center;
    float: right;
    padding: 0.5em 2em;
    margin-right: 2em;
    margin-top: 1em;
    position: relative;
    z-index: 1;
    background-color: var(--primary);
    border: 3px solid var(--primary);
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
}

.video-button:hover {
    color: white;
    border:  3px solid black;
    text-decoration: none;
}
.video-button:hover:before {
    width: 100%;
}
.video-button:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s;
}

/* ---------------------  TOUES  --------------------- */



.text-control-1 h2 {
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .text-control-1 h2 {
        margin-bottom: 16px;
    }
}

.question {
    font-size: 20px;
    line-height: 1.5;
    padding: 20px;
    margin-bottom: 0;
    font-weight: 500;
}

.material-icons.drop {
    padding: 20px;
    font-size: 2rem;
    color: var(--primary);
    transition: transform 0.5s ease-in-out;
}

.answer {
    font-size: 16px;
    padding: 16px;
    padding-left: 52px;
    padding-bottom: 32px;
    background-color: white;
    overflow: hidden;
}

.answer .row {
    padding-bottom: 2em;
}

.answer h3 {
    color: var(--primary);
    width: 80%;
    font-weight: 700;
}

.answer p {
    padding-top: 1.5em;
    width: 90%;
}

.answer img {
    width: 500px;
    height: 300px;
}

.answer .directions-link {
    padding-top: 1em;
    float: left;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    color: var(--primary);
}

.answer .directions-link:hover {
    opacity: 0.6;
}

.accordion {
    background-color: #ffffff;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

@media only screen and (max-width: 768px) {
    .accordion {
        margin-bottom: 16px;
    }
}

.question-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.25s ease-in-out;
}

.answer-wrapper {
    display: none;
}

.icon-expend {
    transform: rotateX(180deg);
    transition: transform 0.75s ease-in-out;
}

.active,
.accordion:hover .question-wrapper {
    background-color: var(--offwhite);
}

.grid-container {
    padding-bottom: 2em;
}

.grid-item {
    padding: 1em;
    font-weight: 700;
}

.grid-item:nth-child(odd) {
    background-color: white;
    border: 2px solid var(--offwhite);
}

.grid-item:nth-child(even) {
    background-color: var(--offwhite);
    border: none;
}

.grid-item .col:nth-child(even) {
    border-left: 0.3em solid var(--offwhite);
    text-align: right;
    font-weight: 500;
}

.mobile-card {
    display: none;
}

.program-subpage {
    padding-bottom: 10em;
}

.program-subpage h5 {
    text-align: center;
}

.program-subpage h1 {
    text-align: center;
    font-weight: 500;
    font-size: 4em;
    width: 60%;
    margin: 0 auto;
}

.program-subpage img {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 2em;
}

.program-subpage a {
    margin-top: 2em;
}

#holiday-traditions {
    height: 320px;
    object-fit: cover;
}

#holiday-traditions-page {
    height: 800px;
    object-fit: cover;
}
  
@media screen and (max-width: 940px) {

    #visit-us-page .google-map {
        width: 100%;
        height: 300px;
        margin-bottom: 2em;
    }
    
    #exhibits-page .white-card {
        display: none;
    }

    .mobile-card img {
        width: 100%;
    }

    .mobile-card {
        display: block;
        width: 300px;
    }
    
    .mobile-content {
        display: block;
        padding: 1em;
    }

    .mobile-content img,
    .mobile-content iframe {
        width: 100%;
        padding-bottom: 1em;
    }
    
    .mobile-full-text {
        display: none;
    }
    
    .mobile-close {
        display: none;
    }

    .mobile-content .card-title {
        color: var(--primary);
        border-bottom: 0.1em solid var(--primary);
    }

    .programs-card {
        margin-bottom: 2em;
        height: auto;
    }

    .programs-top-row .card {
        margin-bottom: 2em;
    }    

    .answer {
        padding-left: 1em;
    }

    .answer img {
        width: 100%;
        height: 100%;
    }

    .directions-link {
        padding-bottom: 2em;
    }

    .program-subpage h1 {
        font-size: 2em;
        width: 100%;
    }

    #holiday-traditions-page {
        height: 500px;
        object-fit: cover;
    }    

    .video-button {
        margin-right: 0em;
        float: none;
        margin: 0 auto;
        left: 22%;
        margin-top: -2em;
        margin-bottom: 2em;
    }

    .programs-vids-section .calendar h2 { 
        margin-top: -2em;
        margin-bottom: 0em;
    }
    
    .programs-vids-section .calendar .video-button {
        margin-top: -2.5em;
        margin-bottom: 7em;
        left: 2.5em;
    }
    

}
