.footer-top {
    background-color: var(--offwhite);
    padding-top: 3em;
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: 1em;
    width: 80%;
    margin: 0 auto;
    position: relative;
    top: 60px;
    z-index: 9;
    max-width: 1200px;
    box-shadow: var(--lightshadow);
}

.footer-top h4 {
    font-weight: 700;
}

.footer-top  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    float: right;
    position: relative;
}
    
.footer-top  ul a {
    border-radius: 35px;
    padding: 20px;
    font-size: 1.5em;
    color: var(--primary);
    transition: 0.2s;
    background-color: white;
}
    
.footer-top ul a:hover {
    color: white;
    background-color: var(--primary);    
}

#footer-section {
    background-color: var(--primary);  
    position: relative;
    top: 0px;
}

.footer-body {
    max-width: 1500px;
    margin: 0 auto;
}
  
.footer-title {
    margin-top: 40px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 200px;
    text-align: left;
}
  
.footer-img {
    margin-top: 40px;
    width: 200px;
}
  
.footer-phone, .footer-email, .footer-local {
    color: white;
    letter-spacing: 0.3vh;
}

.footer-phone:hover, .footer-email:hover, .footer-local:hover  { 
    color: var(--primary);
}
  
.footer-txt {
    padding-top: 7em;
    color: white;
    position: relative;
    letter-spacing: 0.3vh;
}

.footer-txt ul {
    text-align: center;
}

.footer-txt ul a {
    color: #fff;
    text-decoration: none;
}

.footer-txt ul a:hover {
    opacity: 0.7;
}
  
.copyright-footer {
    color: white;
    text-align: left;
    padding: 10px;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.copyright-txt {
    margin: 0 auto;
}

.copyright-txt span a {
    text-decoration: none;
    color: #fff;
    font-weight: 800;
}

.copyright-txt span a:hover {
    opacity: 0.7;
}

#right-side {
    text-align: right;
}
  
.footer-col {
    float: left;
    width: 25%;
    padding: 10px;
}
  
.footer-row:after {
    content: "";
    display: table;
    clear: both;
}

.links {
    opacity: 0;
}

.links ul li {
    border: 2px solid white;
    border-radius: 25px;
    padding: 10px;
}

.links ul li:hover {
    background-color: lightgrey;
}
  
@media screen and (max-width: 940px) {
    .footer-col {
        width: 100%;
    }
    footer * {
        border-radius:0;
    }

    .footer-txt {
        left: 0%;
    }

    .copyright-footer {
        border-top: none;
        color: white;
        text-align: center;
        font-size: 0.7em;
    }

    .footer-top {
        padding-left: 1.9em;
        padding-right: 1.9em;
        text-align: center;
    }

    .footer-top h4 {
        padding-bottom: 1.2em;
    }

    .footer-top .links-col {
        padding: 0px;
        display: flex;
        left: -1em;
        padding-top: 1.5em;
    }

    .footer-top ul {
        float: none;
        width: 120px;
    }

    #right-side {
        padding-left: 4em;
        padding-right: 4em;
        text-align: center;
        font-size: 0.8em;
    }

    #footer-section .row {
        margin-top: 2em;
        border-top: 0.1em solid #fff;
    }
}