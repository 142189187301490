body {
    overflow-x: hidden;
}

/* ------------------------  HOME HERO SECTION ------------------------ */

#home-hero-image-body {
    position: relative;
    z-index: 99;
    text-align: center;
    color: white;
    clip-path: inset(0);
    height: 800px;
    background-color: black;
}

#home-hero-image-body::before {
    z-index: 1;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: #000;
    opacity: 0.1;
}

#home-hero-image-body::after {
    content: '';
    width: 100%;
    height: 0.2em;
    display: block;
    position: absolute;
    background: #fff;
    opacity: 1.0;
    top: 98%;
    z-index: 99;
}


#home-hero-image-body img {
    z-index: 0;
    width: 100%;
    -webkit-filter: brightness(50%); 
    filter: brightness(50%);
    background-repeat: no-repeat;
    object-fit: cover;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 100%;
}

#home-hero-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 900px;
}

.home-hero-content-text {
    width: 100%;
    margin: 0 auto;
}

.home-hero-content-text h1 {
    font-size: 3em;
    line-height: 1.05;
    font-weight: 900;
    font-family: 'Libre Baskerville';
}

.home-hero-content-text h2 {
    font-size: 6em;
    font-weight: 900;
    font-family: 'Libre Baskerville';
}

.home-hero-content-text p {
    padding-bottom: 1em;
    font-size: 1.5em;
    width: 500px;
    margin: 0 auto;
}

.home-hero-content-text .section-header {
    color: var(--primary);
    color: #00b0ff;
}

.home-hero-content-text .section-header:before {
    background-color:#00b0ff;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 15%;
    right: 0.5em;
    margin-left: 0%;
}

.home-hero-content-text .section-header:after {
    background-color: #00b0ff;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 15%;
    right: -0.5em;
    margin-left: 0%;
}

.home-hero-content .main-button {
    color: #fff;
}


#CNY_Drone_Video {
    display: block;
}

.mobile_img {
    display: none;
}
  

.save-date {
    padding-bottom: 5em;
    padding-top: 3em;
}

.save-date h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville';
}

.save-date p {
    color: black;
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 0.05em;
    font-weight: 400;
    font-family: "Palatino Linotype";
}

.save-date img {
    /* border-bottom: 0.5em solid var(--primary); */
    height: 100%;
    object-fit: contain;
}

/* ------------------------  HOME SERVICES SECTION ------------------------ */

#home-services-section {
    padding: 3em;
    background-color: white;
    color: black;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 5em;
}

#home-services-section .card {
    border-top: 4px solid var(--primary);
    color: #000;
    padding: 10px;
    width: auto;
    box-shadow: rgb(25, 25, 25, 0.1) 0px 8px 30px 0px;
    position: relative;
    top: 0;
    transition: top ease 0.4s;
    height: 100%;
    width: 100%;
    z-index: 999;
}

#home-services-section .card .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
}

#home-services-section .card .card-service-img {
    border: 3px solid var(--primary);
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 10px;
    margin: 0 auto;
    position: relative;
    top: -50px; 
    margin-bottom: -30px; 
}


#home-services-section .card:hover {
    top: -10px;
}

#home-services-section .card :hover .card-img {
    transform: scale(1.2);
}

#home-services-section .card h2 {
    padding-top: 0.8em;
    color: var(--primary);
    font-weight: 900;
    font-size: 3em;
}

#home-services-section .card p { 
    padding: 1.1em;
}

#home-services-section h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville';
}

#home-services-section .section-header {
    color: var(--primary);
}

.home-services-content-text {
    padding-top: 5em;
}

.home-services-content-text p {
    width: 60%;
    margin: 0 auto;
}

#home-services-section .row {
    position: relative;
    top: -12em;
    margin-bottom: -12em;
}

#home-services-section .main-button {
    margin-top: 3em;
}

/* ------------------------  HOME ABOUT SECTION ------------------------ */

#home-about-section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.home-about-content-text {
    padding-bottom: 40px;
}

.home-about-content .section-header {
    color: var(--primary);
}

.home-about-content h1 {
    font-weight: 600;
    font-size: 2em;
    font-family: 'Libre Baskerville';
}

.home-about-content p {
    color: #4c4c4c;
    font-weight: 300;
    width: 80%;
}

#home-about-section img {
    width: 600px;
    height: 400px;
    object-fit: cover;
}

.home-hall {
    max-width: 1200px;
    margin: 0 auto;
}

.home-hall {
    font-weight: 800;
    text-align: center;
    font-family: "Libre Baskerville";
    padding-top: 3em;
    padding-bottom: 6em;
}

.home-hall h1 {
    padding-bottom: 1em;
}

/* ------------------------  HOME EXTRA CONTENT SECTION ------------------------ */

#home-extra-section {
    background-color: #f7f7f7;
    padding-top: 5em;
    padding-bottom: 4em;
}

#home-extra-section .section-header {
    color: var(--primary);
}

#home-extra-section .container {
    margin: 0 auto;
}

#home-extra-section .container .row {
    margin: 0 auto;
}

#home-extra-text p {
    width: 80%;
}

#home-extra-text ul i {
    padding-top: 0.2em;
    padding-right: 0.5em;
}

#home-extra-text .fa {
    color: var(--primary);
}

#home-extra-section img {
    width: 100%;
    padding: 0;
}

#extra-service-card img {
    border-radius: 30px;
    border-bottom: 5px solid var(--primary);
}

.switch-body {
    padding-top: 5em;
    padding-bottom: 5em;
}

.switch-button {
	width: 400px;
	height: 40px;
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translate3D(-50%, -50%, 0);
	will-change: transform;
	z-index: 197 !important;
	cursor: pointer;
	transition: .3s ease all;
	border: 2px solid var(--primary);
}

.switch-button-case {
    display: inline-block;
    background: none;
    width: 49%;
    height: 100%;
    color: grey;
    position: relative;
    border: none;
    transition: .3s ease all;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding-bottom: 1px;
}

.switch-button-case:hover{
    color: #151515;
    cursor: pointer;
}

.switch-button-case:focus {
     outline: none;
}
.active {
    color: #151515;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    transition: .3s ease-out all;
}

.active-case {
    color: #151515;
}

/* ------------------------  HOME WHY US SECTION ------------------------ */


#home-why-us-section {
    margin-bottom: -250px;
}

#home-why-us-image-body {
    position: relative;
    z-index: 99;
    text-align: center;
    color: white;
    clip-path: inset(0);
    height: 700px;
    background-color: var(--primary);
}

#home-why-us-image-body::before {
    z-index: 1;
    content: '';
    width: 100%;
    height: 700px;
    display: block;
    position: absolute;
    background: #000;
    opacity: 0.01;
}

#home-why-us-image-body img {
    z-index: 0;
    width: 100%;
    -webkit-filter: brightness(30%); 
    filter: brightness(30%);
    background-repeat: no-repeat;
    object-fit: cover;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 100%;
}

#home-why-us-content {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.home-why-us-content-text h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville';
}

.home-why-us-content-text p {
    width: 100%;
    margin: 0 auto;

}

#home-why-us-section .section-header {
    color: var(--primary);
}
.home-programs-button {
    margin-top: 3em;
    display: flex;  
    align-items: center; 
    justify-content: center;
}

#home-why-us-content h1 {
    padding-bottom: 30px;
}

#home-why-us-content .row {
    color: black;
}

.home-why-us-items {
    position: relative;
    z-index: 99;
    top: -380px;
    margin: 0 auto;
}

.home-why-us-items .card {
    background-color: transparent;
    text-align: center;
    padding: 10px;
    border: 2px solid var(--primary);
    color: #fff;
}

.home-why-us-items .card h2 {
    color: #fff;
    font-weight: 700;
    font-size: 1.8em;
    font-family: 'Libre Baskerville';
}

.home-why-us-items .card p {
    width: 80%;
    margin: 0 auto;
    line-height: 1.5em;
    padding-bottom: 20px;
}

.home-why-us-items .card:hover {
    color: #fff;
}

.home-why-us-items .card:hover:before {
    height: 100%;
}

.home-why-us-items .card:before { 
    content: '';
    position: absolute;
    display: block;
    height: 0;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--primary);
    width: 100%;
    -webkit-transition: height .3s;
    transition: height .3s;
}

.home-why-us-items .card .card-service-img {
    border: 3px solid var(--primary);
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 10px;
    margin: 0 auto;
    position: relative;
    top: -50px; 
    margin-bottom: -30px; 
}

/* ------------------------  HOME SHOP SECTION ------------------------ */

#home-shop-section {
    background-color: var(--primary);
    color: white;
    max-width: 1500px;
    margin: 0 auto;
}

#home-shop-section img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.home-shop-content {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 800px;
    padding-left: 5em;
}

.home-shop-content h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville';
}

.home-shop-content p {
    font-weight: 300;
    width: 80%;
}

#home-shop-section img {
    position: relative;
    width: 100%;
}

/* ------------------------  HOME news SECTION ------------------------ */


#home-news-section {
    margin-bottom: -100px;
    padding: 3em;
    padding-bottom: 200px;
    padding-top: 6em;
}

#home-news-section .section-header {
    color: var(--primary);
    text-align: center;
}

.home-news-header-text {
    text-align: center;
    padding-bottom: 4em;
}

.home-news-header-text h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville';
}

.home-news-header-text p {
    width: 50%;
    margin: 0 auto;
}

#home-news-section .row {
    padding-bottom: 40px;
}

.news-card {
    background-color: var(--offwhite);
    border-radius: 0px;
    border: none;
    border-bottom: 0.2em solid var(--primary);
}

#news-page .news-card img {
    height: 400px;
    object-fit: cover;
}

#news-page .top-news img {
    height: 650px;
}

#home-page .news-card img {
    height: 400px;
    object-fit: cover;
}

#home-page .top-news img {
    height: 650px;
}

.news-read-more {
    float: right;
}

.news-card .card-title {
    font-weight: 700;
    color: var(--primary);
}

.news-card {
    height: 100%;
}

.news-card .card-body {
    padding: 2.5em;
    height: 360px;
}

.top-news .card-body {
    height: 250px;
}

.news-card .card-link {
    float: right;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    color: var(--primary);
}

.news-card .card-link:hover {
    opacity: 0.6;
}

/* ------------------------  HOME CONTACT SECTION ------------------------ */


#home-contact-section {
    height: 470px;
    padding-top: 100px;
    margin-bottom: 100px;
}

#home-contact-image-body {
    position: relative;
    z-index: 99;
    text-align: left;
    color: white;
    clip-path: inset(0);
    height: 470px;
    background-color: #00285D;
}

#home-contact-image-body::before {
    z-index: 1;
    content: '';
    width: 100%;
    height: 470px;
    display: block;
    position: absolute;
    background: #0A090F;
    opacity: 0.5;
}

#home-contact-image-body img {
    z-index: 0;
    width: 100%;
    -webkit-filter: brightness(30%); 
    filter: brightness(30%);
    background-repeat: no-repeat;
    object-fit: cover;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 100%;
}

#home-contact-text {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

#contact-map {
    width: 100%;
    height: 130%;
}

#home-contact-text .main-button-white {
    background-color: white;
    color: black;
    border: none;
}

#home-contact-text .main-button-white:hover:before {
    display: none;
}

#home-contact-text .main-button-white:hover {
    background-color: rgb(227, 226, 226);
}

.home-contact-location {
    padding-bottom: 10px;
}

.home-contact-location p {
    line-height: 1em;
}

#home-contact-text h2 {
    padding-bottom: 2px;
}

.fade-in-up {
    opacity:0;
    visibility: hidden;

    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.fade-in-up-load {
    opacity:0;
    visibility: hidden;

    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    
    -webkit-transition: all .8s ease-in-out;
    transition: all .8s ease-in-out;
}

#join-hunt {
    margin-right: 1em;
    margin-bottom: 1em;
}

#utica-clues {
    margin-right: 1em;
}

/* MOBILE SCREENS */

@media screen and (max-width: 940px) {

    body {
        overflow-x: hidden;
    }

    /* ------------------- HOME HERO MOBILE -------------------   */

    #home-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #home-hero-content {
        top: 50%;    
    }

    .home-hero-content {
        padding: 0px;
        width: 100%;
        padding-bottom: 40px;
    }

    .home-hero-content-text {
        position: relative;
        width: 400px;
    }

    .home-hero-content-text p {
        width: 400px;
    }

    .home-hero-content-text h1 {
        width: 100%;
        font-size: 2em;
    }

    .home-hero-content-text h2 {
        font-size: 4em;
    }

    .hero-img {
        padding: 0px;
        width: 400px;
        left: -50px;
        position: relative;
    }

    .hero-img img {
        border-radius: 0px;
    }

    .hero-img:after {
        position: relative;
        display: block;
        content: '';
        height: 6px;
        width: 50%;
        margin: 0 auto;
        top: 1em;
        background-color: var(--primary); 

    }

    #home-hero-image-body {
        height: 900px;
    }

    #CNY_Drone_Video {
        display: none;
    }
    
    .mobile_img {
        display: block;
    }
    /* ------------------- HOME SERVICES MOBILE -------------------   */

    #home-services-section {
        padding-left: 0em;
        padding-right: 0em;
    }

    #home-services-section .card {
        margin-bottom: 20px;
        left: -1em;
    }

    #home-services-section h1 {
        padding-bottom: 10px;
    }

    .home-services-content-text p {
        width: 100%;
        padding-top: 60px;
    }

    .home-services-content-text {
        margin-top: -200px;
    }

    #home-services-section .row {
        padding: 0px;
        width: 360px;
        margin: 0 auto;
        position: relative; 
    }

    #home-services-section .col-lg-4 {
        width: 100%;
        margin-bottom: 20px;
    }

    #home-services-section .card p {
        padding: 12px;
    }

    #home-services-section .card:hover {
        top: 0px;
    }

    /* ------------------- HOME ABOUT MOBILE -------------------   */


    #home-about-section {
        padding: 10px;
        padding-bottom: 100px;
        padding-top: 6em;
    }

    #home-about-section iframe {
       width: 100%;
       margin: 0 auto;
       padding-bottom: 2em;
    }

    #home-about-section .about-img {
        padding-bottom: 40px;
    }

    #home-about-section .about-img img {
        padding: 0px;
    }

    #home-about-section .about-img:before {
        content: '';
        position: absolute;
        display: block;
        height: 20%;
        width: 40%;
        background-color: var(--primary);
        opacity: 0.2;
        top: 1em;
        left: 0em;
    }

    #home-about-section img {
        width: 100%;
        height: 300px;
        margin-bottom: 2em;
    }

    .home-hall .legends {
        padding-bottom: 3em;
    }

    /* ------------------- HOME EXTRA MOBILE -------------------   */

    #home-extra-text {
        padding-bottom: 2em;
    }

    .switch-button {
        width: 300px;
    }

    /* ------------------- HOME WHY US MOBILE -------------------   */


    #home-why-us-content {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        width: 90%;
    }

    .home-why-us-items {
        position: relative;
        z-index: 99;
        top: -900px;
        margin: 0 auto;
        margin-bottom: -800px;
    }

    #home-why-us-image-body {
        height: 1400px;
    }
    
    #home-why-us-image-body::before {
        height: 1400px;
    }

    .home-why-us-items .card {
        padding: 10px;
        margin-bottom: 70px;
    }

    /* ------------------- HOME news MOBILE -------------------   */


    #home-news-section {
        padding: 0px;
        padding-top: 80px;
        width: 100%;
        padding-bottom: 200px;
    }

    #home-news-section .home-news-header-text p {
        width: 100%;
    }

    #home-news-section .col-lg-6 {
        padding-bottom: 2em;
    }

    #home-news-section .card {
        position: relative;
    }

    /* ------------------- HOME CONTACT MOBILE -------------------   */

    #home-contact-section {
        height: 700px;
    }

    #home-contact-image-body,
    #home-contact-image-body::before {
        height: 700px;
    }

    #home-contact-text .col-lg-6 {
       padding-bottom: 5em;
    }

    #news-page .news-card img {
        height: 100%;
        object-fit: cover;
    }
    
    #news-page .top-news img {
        height: 100%;
    }

    .news-card .card-body {
        padding: 2.5em;
        height: 520px;
    }
    
    .top-news .card-body {
        height: 520px;
    }

    #hunt-clues {
        margin-top: 1em;
        margin-bottom: 2em;
    }
}

